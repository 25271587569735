
import React from 'react';
import  './styles/components/statcard.scss';

const StatCard = (props) => {
  const { number, title, emoji } = props;
  return (
      <div className='stat-card'>
        <div className='content'>
          <p className={`h2 ${props.variant  && 'aqua'} bold pushLeftSm`}>{number}</p>
          <p className={`h2 ${props.variant  && 'aqua'}`}>{title}</p>
        </div>
        <p className='h2 emoji'>{emoji}</p>
      </div>
  ) 
}

export default (StatCard);
