import React, { useState, useEffect } from 'react';
import ClickOutHandler from 'react-onclickout';
import axios from 'axios';
import MilestoneCovo from './MilestoneCovo';
import AddLinkForm from './AddLinkForm';

const ShowMilestone = (props) => {
  const {
    milestone,
    user,
    id, // projectId
    _getProject,
    deleteMilestoneComment,
    exitModal,
    commentId, 
    setCommentId
  } = props;
  
  const [title, setTitle] = useState(milestone.title);
  const [description, setDescription] = useState(milestone.description);
  const [deadline, setDeadline] = useState(milestone.deadline);
  const [complete, setComplete] = useState(milestone ? milestone.complete : false);
  const [comments, setComments] = useState(milestone.comments);
  const [links, setLinks] = useState(milestone.links);
  const [editMode, setEditMode] = useState(false);
  const [addLink, setAddLink] = useState(false);
  const [editComment, setEditComment] = useState('');
  const [editValue, setEditValue] = useState('');

  useEffect(() => {
    if (milestone && id) {
      _getComments()
    }
    // eslint-disable-next-line
  },[])

  const editMilestone = () => {
    axios.post(`/api/project/${id}/milestone/${milestone._id}`, {title: title, description: description, deadline: deadline, complete: complete}).then((res) => {
      setEditMode(false);
      _getProject();
    }).catch((err) => {
      //TODO: Need to handle an error here. 
      console.error('ERROR: ', err);
    })
  }

  const _deleteMilestone = () => {
    axios.post(`/api/project/${id}/milestone/${milestone._id}/delete`).then((res) => {
      _getProject();
      exitModal();
    })
  }

  const _getComments = () => {
    axios.get(`/api/project/${id}/milestone/${milestone._id}/comments`).then((res) => {
      setComments(res.data)
    })
  }

  const _resetComments = () => {
    _getComments()
  }

  const _addComment = (commentContent) => {
    axios.post(`/api/project/${id}/milestone/${milestone._id}/comment`, {content: commentContent}).then((res) => {
      _resetComments();
    })
  }

  const _editComment = async (comment) => {
    try {
      await axios.post(
        `/api/project/${comment.projectId}/milestone/${comment.milestoneId}/comment/${comment._id}/edit`, 
        {newContent: editValue}
      );
      setEditComment('')
      setEditValue('')
      _resetComments();
    } catch {
      console.log('uh oh... should handle an error here better.');
    }
  }

  const _deleteComment = (comment) => {
    axios.delete(`/api/project/${comment.projectId}/milestone/${comment.milestoneId}/comment/${comment._id}`).then((res) => {
      _resetComments();
    }).catch((err) => {
      console.log('error:', err);
      _resetComments();      
    })
  }

  const _addLink = (link) => {
    axios.post(`/api/project/${id}/milestone/${milestone._id}/link`, link).then((res) => {
      // Need to to do this for now which closes the modal, later/soon need to refactor this
      // so when we open the milestone modal it fetches the milestone links also not just
      // the comments on it.
      _getProject();
      const links = res.data;
      setLinks(links)
      setAddLink(false)
    })
  }

  const _deleteLink = (link) => {
    axios.post(`/api/project/${id}/milestone/${milestone._id}/link/${link._id}/delete`).then((res) => {
      _getProject();
      let links = res.data;
      setLinks(links)
    })
  };

  return (
    <>
      {editMode ? (
        <ClickOutHandler onClickOut={editMilestone}>
          <div className="padding">
            <label>Title</label>
            <input value={title} onChange={(e) => setTitle(e.target.value)} />
            <label>Description</label>
            <input value={description} onChange={(e) => setDescription(e.target.value)} />
            <label>Deadline</label>
            <input type="date" value={deadline} onChange={(e) => setDeadline(e.target.value)} />
            Completed: <input type="checkbox" checked={complete} onChange={(e) => setComplete(e.target.checked)} />
            <div className="buttons-container">
              <button className='btn-primary' onClick={editMilestone}>Save</button>
              <button className='btn-danger' onClick={() => setEditMode(false)}>Cancel</button>
            </div>
          </div>
        </ClickOutHandler>
      ) : (
        <>
        <div className="left-side">
          <div onClick={() => setEditMode(true)}>
            <p className="editable title">{title}</p>
            <p className="editable description">{description}</p>  
            <p className="sub-title">DUE DATE</p>
            <p className="due-date">{deadline}</p>
            <div className="line"></div>
            <p className="sub-title">COMMENTS</p>
          </div>

          <MilestoneCovo
            deleteMilestoneComment={() => deleteMilestoneComment()}
            resetComments={_resetComments}
            addComment={_addComment}
            _editComment={_editComment}
            deleteComment={_deleteComment}
            comments={comments}
            id={id}
            milestoneId={milestone._id}
            user={user}
            commentId={commentId}
            setCommentId={setCommentId}
            editComment={editComment}
            setEditComment={setEditComment}
            editValue={editValue}
            setEditValue={setEditValue}
          />
        </div>

        <div className="right-side">
          <p onClick={() => setEditMode(true)} className="due-date">{complete ? 'Complete' : 'Incomplete'}</p>
          <div className="line"></div>
          <p className="sub-title">LINKS</p>
          <button onClick={() => setAddLink(!addLink)} className="action-btn">+ Add Link</button>
          {addLink ? (
            <>
              <AddLinkForm close={() => setAddLink(false)} addLink={_addLink} />
            </>
          ) : ''}

          {links ? links.map((link) => (
            <button className={`action-btn ${link.background}`}>
              <a href={link.url} target="_blank" rel="noopener noreferrer"><span>{link.title}</span></a>
              <span onClick={() => _deleteLink(link)}>X</span>
            </button>
          )) : ''}


          <div className="line"></div>
          <p className="sub-title">Danger Zone</p>
          <button
            onClick={_deleteMilestone}
            className="action-btn red"
          >
            Delete Milestone
          </button>


        </div>
        </>
      )}
    </>
  )
}

export default ShowMilestone