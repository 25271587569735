import React, { Component,  } from 'react';
import { NavLink, Link }from 'react-router-dom';
import axios from 'axios';
import ClickOutHandler from 'react-onclickout';
import '../styles/sidemenu.scss';
import logo from '../../assets/logo.png'
class LeftBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showModal: false,
      user: {},
      url: '',
      img: localStorage.getItem('img') ? localStorage.getItem('img') : '',
    }
  }


  componentDidMount() {
    this.getUser();

    if (this.state.img) {
      localStorage.setItem('img', this.state.img);
    }
  }

  getUser = () => {
    axios.get('/api/current_user').then((user) => {
      this.setState({
        user: user.data,
        img: user.data.profilePhoto
      })
    })
  }

  postPhoto() {
    axios.post('/api/user/photo', {profilePhoto: this.state.url }).then((user) => {
      this.getUser();
      this.setState({showModal: false})
    })
  }

  render() {
    return (
      <div className="left-bar">
        <div className="top-section">
            <Link to="/">
              <img className='logo' src={logo} alt='Logo'></img>
            </Link>

            <div onClick={() => this.setState({showModal: !this.state.showModal})}
             style={{
              backgroundImage: `url(${this.state.img})`,
              backgroundSize: 'contain' }}
             className="profile-photo"
             >
            </div>
         
       
        </div>
        <div className="middle-section">
          {this.state.user.type === 'contractor' ? (
          <>
            <NavLink activeClassName="active" to="/dashboard"><i className="fa fa-home"></i></NavLink>
            <NavLink activeClassName="active" to="/clients"><i className="fa fa-user-friends"></i></NavLink>
            <NavLink activeClassName="active" to="/projects"><i className="fa fa-briefcase"></i></NavLink>
            <NavLink activeClassName="active" to="/upgrade"><i className="fa fa-wallet"></i></NavLink>
            <NavLink activeClassName="active" to="/settings"><i className="fa fa-cog"></i></NavLink>
          </>
          ) : (
            <>
              <NavLink activeClassName="active" to="/dashboard"><i className="fa fa-home"></i></NavLink>
              <NavLink activeClassName="active" to="/projects"><i className="fa fa-briefcase"></i></NavLink>
              <NavLink activeClassName="active" to="/settings"><i className="fa fa-cog"></i></NavLink>
            </>
          )}
        </div>

        <div className="bottom-section">
          <a href="/api/logout">Log out</a>
        </div>




        {this.state.showModal ? (
          <div className="modal-overlay">
          <ClickOutHandler onClickOut={() => this.setState({showModal: false})}>
            <div className="modal project-modal">
              <h2 style={{color: 'black'}}>Change Photo</h2>
              <label>Image URL</label>
              <input value={this.state.url} onChange={(e) => this.setState({url: e.target.value})} />
              
              <button onClick={() => {this.postPhoto()}}>Change profile picture</button>
            </div>
            </ClickOutHandler>
          </div>
        ) : ''}

      </div>
    )
  }
}

export default LeftBar;