import { combineReducers } from 'redux';
import auth from './authReducer';
import clients from './clientReducer';
import projects from './projectReducer';
import dashboard from './dashboardReducer';

const rootReducer = combineReducers({
  auth,
  clients,
  projects,
  dashboard,
});

export default rootReducer;

export type AppState = ReturnType<typeof rootReducer>;
