
import React from 'react';
import ClickOutHandler from 'react-onclickout';
import { withRouter } from 'react-router-dom';

const DeleteModal = (props) => {

  const {
    setDeleteModalOpen,
    match
  } = props;

  const go = () => {
    props.deleteProject(match.params.id)
    props.history.push('/projects');
  }


  return (
    <div className="modal-overlay">
      <ClickOutHandler onClickOut={() => props.setDeleteModalOpen(false)}>
        <div className="modal project-modal">
          <p className='title2 center'>Are you sure you'd like to delete this project?</p>

          <div className='spread-row delete-options center'>
            <button className='btn-danger' onClick={go}>Yes</button>
            <p className='button pointer' onClick={() => setDeleteModalOpen(false)}>No</p>
          </div>
        </div>
      </ClickOutHandler>
    </div>
  ) 
}

export default (withRouter(DeleteModal));
