import React, { Component } from 'react';
import ClickOutHandler from 'react-onclickout';
import { connect } from 'react-redux';
import { fetchProjects, createProject, fetchClients } from '../../../redux/actions';
import { withRouter } from 'react-router-dom';
import shipBlob from '../../../assets/start.svg'

class NewProjectModal extends Component {
  state = {
    newTitle: '',
    showNew: false,
    clients: [],
    showClients: false,
    clientName: '',
    client: {},
    user: {},
    hoverIndex: '',
  }

  _createProject = async () => {
    await this.props.createProject(this.state);
    this._go();
  }

  _go = function() {
    const id = this.props.projects.projects[this.props.projects.projects.length-1]._id;
    this.props.history.push(`/projects/${id}`);
    this.props.leave();
  }

  render() {
    return (
        <div className="modal-overlay">
          <ClickOutHandler onClickOut={this.props.leave}>
            <div className="modal project-modal">
              <center>
                <img alt='New Project' className='modal-top-blob' src={shipBlob}></img>
              </center>
              <p className='h2 bold project-new-title'>New Project <span aria-label='rocket' role='img'>🚀</span></p>
              <input className='major-input' name="title" placeholder="What's the Name" value={this.state.newTitle} onChange={(e) => this.setState({newTitle: e.target.value})} />
              {this.props.clients.length !== 0 ? (
                <>
                  <label>Client Name</label>
                  <div className="client-adder">
                  <div onClick={() => this.setState({showClients: !this.state.showClients})} className="card-input-imp select-employee-form">{this.state.clientName ? this.state.clientName : 'Select Client'}</div>
                    {this.state.showClients ? (
                      <ClickOutHandler onClickOut={() => this.setState({showClients: !this.state.showClients})}>
                        <div className="employee-cal-drop-down on-edit">
                          {this.props.clients.map((data, index) => (
                            <p key={data._id} onClick={() => this.setState({client: data, showClients: !this.state.showClients, clientName: `${data.firstName} ${data.lastName}`})}>{data.firstName} {data.lastName}</p>
                          ))}
                        </div>
                      </ClickOutHandler>
                    ) : '' 
                    }
                  </div>
                </>
              )
              : ''}
              { this.props.auth.type === 'contractor'
                ? 
                  <center>
                    <button
                      disabled={this.state.newTitle.length < 1}
                      className='btn-project btn-primary'
                      onClick={this._createProject}
                    >
                      Create
                    </button> 
                    {/* <p hidden={this.state.newTitle.length } className='error-msg'>Please enter project name</p> */}
                  </center>
                :
                  null
              }
            </div>
           </ClickOutHandler>
        </div>    )
  }
}

function mapStateToProps({ auth, clients, projects }) {
  return { auth, clients, projects };
}

function mapDispatchToProps() {
  return {
    fetchProjects,
    createProject,
    fetchClients
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(withRouter(NewProjectModal));