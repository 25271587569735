import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../redux/actions';
import './styles/styles.scss';
import './styles/common.scss';

import Landing from './landing/Landing';
import Dashboard from './dashboard/Dashboard';
import Clients from './dashboard/clients/Clients';
import ProjectsContainer from './dashboard/projects/ProjectsContainer/ProjectsContainer';
import ProjectContainer from './dashboard/projects/showproject/ProjectContainer/ProjectContainer';
import ProjectSettingsContainer from './dashboard/projects/showproject/ProjectSettings/ProjectSettingsContainer';
import SetUp from './setup/SetUp';
import ClientStep1 from './setup/client/Step1';
import ContractorStep1 from './setup/contractor/Step1';
import ContractorStep2 from './setup/contractor/Step2';
import FinalSetUpStep from './setup/Final';
import LeftBar from './dashboard/LeftBar';
import Upgrade from './upgrade/Upgrade';
import ErrorBoundary from './ErrorBoundary/ErrorBoundary';

import ClientSignUp from './setup/ClientSignUp';
import Settings from './settings/Settings';

class App extends Component {

  componentDidMount() {
    this.props.clearProjectErrors();
    this.props.fetchUser();
    this.props.fetchClients();
    this.props.fetchProjects();
  }

  render() {  
    return (
      <ErrorBoundary>
        <BrowserRouter>
          {
            this.props.auth ?
              <Switch>
                <Route exact path="/setup" component={SetUp} />
                <Route exact path="/setup/client/1" component={ClientStep1} />
                <Route exact path="/setup/contractor/1" component={ContractorStep1} />
                <Route exact path="/setup/contractor/2" component={ContractorStep2} />
                <Route exact path="/setup/final" component={FinalSetUpStep} />
                
                <div className="right-of-bar">
                  <LeftBar />
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/clients" component={Clients} />
                  <Route
                    exact
                    path='/projects/:id/:milestoneId'
                    component={() => <ProjectContainer goToMileStone={true} />}
                  />
                  <Route
                    exact
                    path="/project/:id/settings"
                    component={ProjectSettingsContainer}
                  />
                  <Route
                    exact
                    path="/projects"
                    component={ProjectsContainer}
                  />
                  <Route
                    path="/projects/:id"
                    component={ProjectContainer}
                  />
                  <Route exact path="/upgrade" component={Upgrade} />

                  <Route exact path="/settings" component={Settings} />
                </div>
              </Switch>
            :
              <>
                <Route exact path="/" component={Landing} />
                <Route path="/new-client/:id" component={ClientSignUp} />
              </>
          }
        </BrowserRouter>
      </ErrorBoundary>
    );
  };
};

function mapStateToProps({ auth, clients, projects }) {
  return { auth, clients, projects };
}

export default connect(mapStateToProps, actions)(App);