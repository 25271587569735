import React, { Component } from 'react';
import axios from 'axios';
import { fetchClients, fetchUser } from '../../../redux/actions';
import { connect } from 'react-redux';


class SingleContact extends Component {

  state = {
    hovered: false
  }

  deleteContact = () => {
    axios.post(`/api/client/${this.props.client._id}/delete`).then(() => {
      this.props.reRender();
    })
  }

  render() {
    return (
      <div className="relative">
        <div onMouseEnter={() => this.setState({hovered: true})} onMouseLeave={() => this.setState({hovered: false})} key={this.props.client._id} className="client-row hover-effect">
          <div>{this.props.client.firstName} {this.props.client.lastName}</div>
          <div>{this.props.client.email}</div>
          <div>{this.props.client.phoneNumber}</div>
          {this.state.hovered ? (
            <button onClick={this.deleteContact} className="delete-client">
              <i className="fas fa-trash-alt"></i>
            </button>
          ) : ''}
        </div>
      </div>
    )
  }
}

function mapStateToProps({ auth, clients }) {
  return { auth, clients };
}

export default connect(mapStateToProps, {fetchClients, fetchUser})(SingleContact);