import React from 'react';

const ProgressBar = (props) => {
    const { percent, _findProjectCompletion, project } = props;
    const percentageAmount = _findProjectCompletion(project);
    return (
        <div className='progress-bar-bg'>
          <div  id={percent} className='filled-bar no-padding' style={{width: percentageAmount + '%'}}></div>
          {/* <p className='percent-number no-padding'>{percentageAmount}%</p> */}
        </div>
    )
}
export default ProgressBar;
