import React from 'react';
import './styles/components/addicon.scss';

const AddIcon = (props) => {
    const { toggleModal }  = props;
    return (
        <div onClick={toggleModal} className='add-icon pointer'>
            <p>+</p>
        </div>
    )
}
export default AddIcon;
