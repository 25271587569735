import React, { useState } from 'react';
import * as moment from 'moment'
import axios from 'axios';

const MilestoneCovo = (props) => {

  const {
    addComment,
    deleteComment,
    _editComment,
    comments,
    user,
    setCommentId,
    editValue,
    setEditValue,
    editComment,
    setEditComment,
    resetComments,
    id,
    milestoneId,
    comment
  } = props;

  const [showWriter, setShowWriter] = useState(false);
  const [commentContent, setCommentContent] = useState('');

  const _addComment = () => {
    addComment(commentContent);
    setShowWriter(false)
    setCommentContent('')
  };
  
  const momentify = (time) => {
    const dateObj = new Date(time)
    return moment(dateObj).fromNow()
  };

  const renderLikedText = (likes) => {
    if (!likes.length) {
      return ''
    }
    if (likes.length === 1) {
      return (
        `${likes[0].firstName} likes this`
      )
    }
    if (likes.length === 2) {
      return (
        `${likes[0].firstName} and ${likes[1].firstName} like this`
      )
    }
    if (likes.length === 3) {
      return (
        `${likes[0].firstName}, ${likes[1].firstName}, and ${likes[2].firstName} like this`
      )
    }
    if (likes.length >= 4) {
      return (
        `${likes.length} people like this`
      )
    }
  };

  const likeComment = (commentId) => {
    axios.post(`/api/project/${id}/milestone/${milestoneId}/comment/${commentId}/like`).then((res) => {
      resetComments();
    })
  }

  console.log('comment:', comment)
  return (
    <div className="conversation-container on-milestone">
      {comments.map((comment) => {
        return (
          <div>   
            <div key={comment.date} className="comment">
            <img className="comment-photo" alt="profile" src={comment.profilePhoto} />
            <div>
              <div className="comment-info">
                <p>
                  <strong>{comment.user}</strong> <span className="date">- {momentify(comment.date)}</span>
                  {comment.edited ? <span className="date"><br />( Edited )</span> : ''}

                  {user.firstName + ' ' + user.lastName === comment.user ? (
                    <button
                      className="edit-btn"
                      onClick={() => {
                        setEditComment(comment._id)
                        setEditValue(comment.content)
                      }}
                    >
                      Edit
                    </button>
                  ) : ''}
                </p>
              </div>
              <div 
                style={{
                  width: 'calc(100% + 66px)',
                  position: 'relative',
                  left: '-66px'
                }} className="comment-content"
              >
                {editComment === comment._id ? (
                  <div className="write-comment edit-mode">
                    <textarea onChange={(e) => setEditValue(e.target.value)} value={editValue} placeholder="Compose your comment" />
                    <button
                      className="btn-primary"
                      onClick={() => {
                        setCommentId(comment._id);
                        _editComment(comment);
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="cancel-btn small-btn"
                      onClick={() => {
                        setEditComment('')
                        setEditValue('')
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <p style={{wordBreak: 'break-word' }}>{comment.content}</p>
                )}              
              </div>
            </div>
            {user.firstName + ' ' + user.lastName === comment.user ? (
              <button onClick={() => deleteComment(comment)} className="delete-btn">
                <i className="fas fa-trash-alt"></i>
              </button>
            ) : ''}
          </div>  
            <div className="like-section">
              <button onClick={ () => likeComment(comment._id)}><i className="far fa-thumbs-up"></i>
                {comment.likes.some(like => like.userId === user._id) ? ' Unlike' : ' Like'}
              </button>
              <div className="comment-likes">
                {renderLikedText(comment.likes)}
              </div>
            </div>
          </div>
        )
      })}
      <br />
      <button
        className="btn-primary"
        onClick={() => {
            setShowWriter(!showWriter)
        }}
      >
        {!showWriter ? "Add Comment" : "Hide"}
      </button>

      {showWriter ? (
        <div className="write-comment">
          <textarea value={commentContent} onChange={(e) => setCommentContent(e.target.value)} placeholder="Compose your comment" />
          <button className="btn-primary" onClick={_addComment}>Send</button>
          <button className="btn-danger" onClick={() => setShowWriter(!showWriter)}>Cancel</button>
        </div>
      ) : ''}

    </div>
  )
}

export default MilestoneCovo;