import React, { Component } from 'react';
import axios from 'axios';

class ClientSignUp extends Component {

  state = {
    clientId: '',
    user: {}
  }

  componentDidMount() {
    let clientId = window.location.href.split('').reverse().join('');
    const aptI = clientId.indexOf('/');

    clientId = clientId.substring(0, aptI);
    clientId = clientId.split('').reverse().join('');
    this.getContractor(clientId);

    localStorage.setItem("contractor", clientId);


    this.setState({clientId})
  }

  getContractor = (clientId) => {
    axios.get(`/api/contractor/${clientId}`).then((user) => {
      this.setState({user: user.data});
    })
  }

  render() {
    return (
      <div className="invite-page">
        <h1>ClientBird</h1>
        <img className="contractor-invite-photo" src={this.state.user.profilePhoto} alt="contractor" />
        <h3>You've been invited by {this.state.user.firstName} {this.state.user.lastName}.</h3>
        <a style={{textAlign: 'center'}} className="final-btn" href="/auth/google">Login with Google</a>
      </div>
    )
  }
}

export default ClientSignUp;