import React, { Component } from 'react';
import axios from 'axios';

class Step2 extends Component {
  constructor(props) {
    super(props)

    this.state = {
      businessName: '',
      businessWebsite: ''
    }
  }

  submitInfo = (contractorType) => {
    axios.post('/api/setup/contractor/2', this.state).then(() => {
      this.props.history.push('/setup/final');
    });
  }

  render() {
    return (
      <div className="setup">
        <h1>ClientBird</h1>
          {/* <img src={agree} className='card-img' alt='decide'></img> */}
          <h2 className="up">Almost finished...</h2>

          <div className="form">
            <label>Business Name</label>
            <input type="text" value={this.state.businessName} onChange={(e) => this.setState({businessName: e.target.value})} placeholder="Johnston Inc" />
            <label>Have a website?</label>
            <input type="text" value={this.state.businessWebsite} onChange={(e) => this.setState({businessWebsite: e.target.value})} placeholder="www.johnston-inc.com" />
          </div>

          <button onClick={this.submitInfo} className="last-step-btn">One last step</button>
          <p onClick={() => this.props.history.goBack()} className="back-btn">Back</p>
      </div>
    );
  }
}

export default Step2;