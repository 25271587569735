import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment'

export default function MilestoneDeadlines({milestones}) {
  return (
    <>
      <div className="deadline-milestone-box">
        <div className="deadline-header">
          <h2>Milestones</h2>
        </div>

        {milestones ? milestones.map((item) => {
          return (
          <Link to={'/projects/' + item.url} key={item._id} className="client-row hover-effect">
            <div>{item.title}{item.projectName}</div>
            <div className="capitalize">{moment(item.deadline).fromNow()}</div>
          </Link>)
          })
          : <p>Loading...</p>
        }
        {milestones.length < 1 ? <p className="client-row">No upcoming deadlines.</p> : ''}

      </div>
    </>
  )
}