import React from 'react';
import NewMilestoneBlob from '../../../../../assets/newmilestone.svg';
import './styles.scss';
const FirstMilestone = (props) => {

  const {
    toggleModal
  } = props;
  
  return (
    <div className='project-show'>
        <h2 className='project-title title'>Create Milestone Timeline</h2>
        <button className='btn-primary' onClick={toggleModal}>START</button>
        <img className='project-blob' alt='new milestone' src={NewMilestoneBlob}></img>
    </div>
  )
}

export default FirstMilestone;
