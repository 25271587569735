import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import { fetchUser } from '../../redux/actions';


class Final extends Component {
  constructor(props) {
    super(props)

    this.state = {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        city: ''
    }
  }

  componentDidMount() {
    this.props.fetchUser();

    this.setState({
      firstName: this.props.auth.firstName,
      lastName: this.props.auth.lastName
    })
  }

  submitInfo = () => {
    axios.post('/api/setup/final', this.state).then((res) => {
      this.props.history.push('/dashboard');
    }).catch((err) => {
      // TODO: Create a var in state that is set here  to update UI nicely.
        console.error('Uh oh -- error:', err);
    });
  }

  validatePhone = (e) => {
    let phone = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !phone[2] ? phone[1] : '(' + phone[1] + ') ' + phone[2] + (phone[3] ? '-' + phone[3] : '');
    this.setState({phoneNumber: phone});
  }

  render() {
    return (
      <div className="setup">
        <h1>ClientBird</h1>
          <h2 className="up">A few last things...</h2>

          <div className="form">
            <label>First Name</label>
            <input type="text" value={this.state.firstName} onChange={(e) => this.setState({firstName: e.target.value})} placeholder="First name" />
            <label>Last Name</label>
            <input type="text" value={this.state.lastName} onChange={(e) => this.setState({lastName: e.target.value})} placeholder="Last name" />
            <label>Phone Number</label>
            <input type="text" value={this.state.phoneNumber} onChange={(e) => this.validatePhone(e)} placeholder="123-456-7890" />
            {/* <label>City</label>
            <input type="text" value={this.state.city} onChange={(e) => this.setState({city: e.target.value})} placeholder="City" /> */}
          </div>

          <button onClick={this.submitInfo} className="final-btn">Finish</button>
          <p onClick={() => this.props.history.goBack()} className="back-btn">Back</p>
      </div>
    );
  }
}

function mapStateToProps({ auth })  {
  return {
    auth
  };
}

function mapDispatchToProps() {
  return {
    fetchUser
  };
};


export default connect(mapStateToProps, mapDispatchToProps())(Final);