
export const findProjectCompletion = (project) => {
    const milestoneCap = project.timeline.length;
    let completed = 0;
    project.timeline.forEach((milestone) => {
        if (milestone.complete) {
            completed++
        }
    })
    let percent = 0;
    if (completed && milestoneCap) {
        percent = (completed/milestoneCap * 100).toFixed();
    }
    
    return percent
}
