import { connect } from 'react-redux';
import { getProject, deleteProject } from '../../../../../redux/actions/index';
import ProjectSettings from './ProjectSettings';
import '../../../../styles/pages/settings.scss';

function mapStateToProps({ projects, auth, clients }) {
  return {
    project: projects.data,
    status: projects.status,
    error: projects.error,
    auth,
    clients
  };
}

export default connect(
  mapStateToProps,
  {
    getProject,
    deleteProject
  }
)(ProjectSettings)
