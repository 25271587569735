export const FETCH_USER = 'fetch_user';
export const FETCH_CLIENTS = 'fetch_clients';
export const FETCH_PROJECTS = 'fetch_projects';
export const FETCH_DASHBOARD_DATA = 'FETCH_DASHBOARD_DATA';
export const CREATE_PROJECT = 'project/CREATE_PROJECT';

export const GET_PROJECT_LOADING = 'project/GET_PROJECT_LOADING';
export const GET_PROJECT_SUCCESS = 'project/GET_PROJECT_SUCCESS';
export const GET_PROJECT_ERROR = 'project/GET_PROJECT_ERROR';
export const DELETE_PROJECT_LOADING = 'project/DELETE_PROJECT_LOADING';
export const DELETE_PROJECT_SUCCESS = 'project/DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_ERROR = 'project/DELETE_PROJECT_ERROR';
export const CLEAR_PROJECT_ERROR = 'project/CLEAR_PROJECT_ERROR';
export const UPDATE_PROJECT_LOADING = 'project/UPDATE_PROJECT_LOADING';
export const UPDATE_PROJECT_SUCCESS = 'project/UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_ERROR = 'project/UPDATE_PROJECT_ERROR';

export const CREATE_NEW_MILESTONE = 'CREATE_NEW_MILESTONE';

export const DELETE_MILESTONE_COMMENT_LOADING = 'milestone/DELETE_MILESTONE_COMMENT_LOADING';
export const DELETE_MILESTONE_COMMENT_SUCCESS = 'milestone/DELETE_MILESTONE_COMMENT_SUCCESS';
export const DELETE_MILESTONE_COMMENT_ERROR = 'milestone/DELETE_MILESTONE_COMMENT_ERROR';
