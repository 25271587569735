import React, { Component } from 'react';

class Landing extends Component {
  render() {
    return (
      <div className="setup">
        <h1>ClientBird</h1>
        <h3>Communication between contractors and clients done right.</h3>
        <a style={{textAlign: 'center'}} className="final-btn" href="/auth/google">Try for free</a>
      </div>
    )
  }
}

export default Landing
