import React from 'react';
import NewProjectModal from '../NewProjectModal';
import ProjectCard from '../ProjectCard';
import TopBar from '../../TopBar';
import { Link } from 'react-router-dom';
import '../../../styles/variables.scss';
import '../styles.scss'
import {findProjectCompletion} from  '../../../../utils/projectCompletion.js';

const Projects = (props) => {
    return (
        <div className="projects">
            <TopBar page="Projects" />
            <div className="projects-list">
    
            {/* Backend should be doing this logic and sending project completion rate when you get the project. */}
            {props.projects.projects.map((project, projectIndex) => {
                const percent = findProjectCompletion(project);
                return <ProjectCard project={project} percent={percent} projectIndex={projectIndex} />
            })}
    
            {!props.auth.paidUser && props.auth.trialUsed ? (
                <Link to={'/upgrade/'} className="project-container smaller trial-over">
                    <div className='project-content'>
                        <h3 className='project-title'>Trial Period Over</h3>
                        <p className='project-bio'>Upgrade your account to create more than one project.</p>
                        <button className='btn-primary'>Upgrade Now</button>
                    </div>
                </Link>
            ) : (
                <div onClick={() => props.setShowNew(true)} className="project-container smaller trial-over new-proj">
                    <div className='project-content'>
                        <h3 className='project-title'>Create New Project</h3>
                        <p className='project-bio'>Set up a new project and onboard your client/s.</p>
                        <button className='btn-primary'>New Project</button>
                    </div>
                </div>
    
                )}
            </div>
            {props.showNew ? (
            <NewProjectModal
                leave={() => props.setShowNew(false)}
            />
            ) : ''}
            {(!props.auth.paidUser && props.auth.trialUsed) || props.auth.type === 'client' ? (
            <>
            {/* users trial expired */}
            </>
            ) : (
            <div onClick={() => props.setShowNew(true)} className="action-btn">
                <i className="fa fa-plus"></i>
            </div>        
            )}
        </div>
    )
}

export default Projects;