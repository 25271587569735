import React, { Component } from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'; //https://www.npmjs.com/package/react-copy-to-clipboard
import SingleContact from './SingleContact';


class ContractClients extends Component {

  state = {
    value: this.props.user._id,
    copied: false
  }

  render() {
    return (
      <div>

        <div className="client-data">
          <div className="client-header">
            <b>Name</b>
            <b>Email</b>
            <b>Phone</b>
          </div>
          {this.props.clients && this.props.clients.map((singleClient) => (
            <SingleContact key={singleClient._id} client={singleClient} reRender={() => this.props.reRender()} />
          ))}
          {this.props.clients.length === 0 ? <div className="client-row">You have no clients yet.</div> : ''}
        </div>

      <h2>Invite new clients by simply sending them this Link:</h2>
      {this.props.user._id ?
        <div>
          <input readOnly className="copy-input-imp" value={`https://app.clientbird.io/new-client/${this.state.value}`} />
          <CopyToClipboard
            text={`https://app.clientbird.io/new-client/${this.state.value}`}
            onCopy={() => this.setState({copied: true})}>
            {this.state.copied ? <button className="copy-button-imp">Copied</button> : <button className="btn-primary">Copy</button>}
          </CopyToClipboard>
        </div>
          :
        <p>loading</p>
        }



      </div>
    )
  }
}

export default ContractClients;