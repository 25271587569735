import React,  { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { fetchProjects, createProject, fetchUser } from '../../../../redux/actions/index.js';
import Projects from './Projects';

const ProjectsContainer = (props) => {
  const [showNew, setShowNew] = useState(false)
  useEffect(() => {
    props.fetchUser();
    props.fetchProjects();
    // eslint-disable-next-line
  }, [fetchUser, fetchProjects]);

  return (
      <Projects
        projects={props.projects}
        auth={props.auth}
        showNew={showNew}
        setShowNew={setShowNew}
      />
  )
}

function mapStateToProps({ auth, clients, projects }) {
    return {
        auth,
        clients,
        projects
    };
}

function mapDispatchToProps() {
    return {
        fetchProjects,
        createProject,
        fetchUser
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps())(ProjectsContainer);