import React from 'react';
import TopBar from '../../../TopBar';
import Timeline from '../Timeline';
import Conversation from '../Conversation';
import FirstMilestone from './FirstMilestone';
import ProgressBar from './ProgressBar'
import AddIcon from '../../../../AddIcon';
import NewMilestoneModal from './NewMilestoneModal';

const Project = (props) => {
  const {
    project,
    before,
    future,
    _getProject,
    _addNewMilestone,
    auth,
    toggleModal,
    modal,
    title,
    description,
    deadline,
    setTitle,
    setDescription,
    setDeadline,
    _findProjectCompletion,
    deleteMilestoneComment,
    milestoneId,
    setMilestoneId,
    commentId,
    setCommentId
  } = props;

  return (
    <div>
      {modal &&
          <NewMilestoneModal
            title={title}
            description={description}
            deadline={deadline}
            setTitle={setTitle}
            setDescription={setDescription}
            setDeadline={setDeadline}
            toggleModal={toggleModal}
            addNewMilestone={_addNewMilestone}
            setMilestoneId={setMilestoneId}
            milestoneId={milestoneId}
          />
        }
      <TopBar
        page={`Projects - ${project.projectName}`}
        projectSettingsLink={`/project/${project._id}/settings`}
        back="/projects"
        showSettings={true}
      />
      { project.timeline.length === 0 ?
          <FirstMilestone
            toggleModal={toggleModal}
          />
        :
        <div className='project-page'>
          <h2 className='title no-padding center'>Milestone Timeline</h2>
          <div className='flex-row'>
            <ProgressBar
              _findProjectCompletion={_findProjectCompletion}
              project={project}
            />
            <AddIcon
              toggleModal={toggleModal}
            />
          </div>
          <div>
            <Timeline
              deleteMilestoneComment={() => deleteMilestoneComment()}
              user={auth}
              _getProject={_getProject}
              id={project._id}
              before={before}
              future={future}
              milestoneId={milestoneId}
              setMilestoneId={setMilestoneId}
              commentId={commentId}
              setCommentId={setCommentId}
            />
          </div>
          <h2>Activity</h2>
          <Conversation id={project._id} comments={project.comments} user={auth} />
          </div>
      }
    </div>
  )
}

export default Project;