import React, { useState } from 'react';
import ClickOutHandler from 'react-onclickout';
import ShowMilestone from './ShowMilestone';
import * as moment from 'moment';
import '../../../styles/timeline.scss';
const Timeline = (props) => {

  const {
    milestoneId,
    setMilestoneId,
    commentId,
    setCommentId
  } = props;

  const [showMilestoneModal, setShowMilestoneModal] = useState(false)
  const [milestoneModal, setMilestoneModal] = useState({})
  
  const showMilestone = (milestone) => {
    setShowMilestoneModal(true)
    setMilestoneModal(milestone)
    setMilestoneId(milestone._id)
  }

  const clearMilestoneModal = () => {
    setShowMilestoneModal(false);
    setMilestoneModal({});
    // props.getProject(props.id);
  }

  return (
    <div className="timeline">

      {props.before.map((milestone) => {
        return (
          <div
            key={milestone._id}
            className={'timeline-card past-card'}
            onClick={() => showMilestone(milestone)}
          >
            <p className='title2'>{milestone.title}</p>

            <p className="body1">
            {
                milestone.comments && milestone.comments.length > 1
                ? <p>{`${milestone.comments.length} comments`}</p> :
                ( milestone.comments && milestone.comments.length === 1 ?
                <p>{`${milestone.comments.length} comment`}</p>  : null)
            }
            </p>
            <p className='body1'>{moment(milestone.deadline).format('MMM DD, YYYY')}</p>

          </div>
        )
      })}
      {
        (props.future && props.future[0]) &&
          <div
            key={props.future[0]._id}
            className={`timeline-card current`}
            onClick={() => showMilestone(props.future[0])}
          >
            <p className='title2'>{props.future[0].title}</p>
            <p className="body1">
              {
                props.future[0].comments && props.future[0].comments.length > 1
                  ? <p>{`${props.future[0].comments.length} comments`}</p> :
                  ( props.future[0].comments && props.future[0].comments.length === 1 ?
                  <p>{`${props.future[0].comments.length} comment`}</p>  : null)
              }
            </p>
            <p className='body1'>{moment(props.future[0].deadline).format('MMM DD, YYYY')}</p>

          </div>
      }

      {
        props.future.length > 1 &&
        props.future.slice(1).map((milestone) => {  
          return (
            <div
              key={milestone._id}
              className={'timeline-card future-card'}
              onClick={() => showMilestone(milestone)}
            >
              <p className='title2'>{milestone.title}</p>
              <p className="body1">
                {
                  milestone.comments && milestone.comments.length > 1
                  ? <p>{`${milestone.comments.length} comments`}</p> :
                  ( milestone.comments && milestone.comments.length === 1 ?
                    <p>{`${milestone.comments.length} comment`}</p>  : null)
                }
              </p>
              <p className='body1'>{milestone.deadline ? moment(milestone.deadline).format('MMM DD, YYYY') : ''}</p>
            </div>
          )
      })}

      {showMilestoneModal && (
        <div className="modal-overlay">
          <ClickOutHandler onClickOut={clearMilestoneModal}>
            <div className="modal project-modal milestone-modal">
              <ShowMilestone
                deleteMilestoneComment={() => props.deleteMilestoneComment()}
                user={props.user}
                _getProject={props._getProject}
                id={props.id}
                milestoneId={milestoneId}
                exitModal={() => setShowMilestoneModal(false)}
                milestone={milestoneModal}
                commentId={commentId}
                setCommentId={setCommentId}
              />
            </div>
          </ClickOutHandler>
        </div>
      )}


  </div>
  )
}


export default Timeline;
