import React, { Component } from 'react';
import axios from 'axios';
import './styles.scss';

class Setup extends Component {

  submitType = (type) => {
    axios.post('/api/setup/type', {type}).then(() => {
      this.props.history.push(`/setup/${type}/1`)
    }).catch((err) => {
      console.error('err:',err);
    })
  }

  render() {
    return (
      <div className="setup">
        <h1>ClientBird</h1>
        <h2>I am a...</h2>
        <div className="setup-boxes">
          <div onClick={() => this.submitType('contractor')} className="setup-box box-img contractor-box-img">
            <h3>Contractor</h3>
          </div>
          <div onClick={() => this.submitType('client')} className="setup-box box-img client-box-img">
            <h3>Client</h3>
          </div>
        </div>
      </div>
    )
  }
}

export default Setup
