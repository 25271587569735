import React, { Component } from 'react';
import axios from 'axios';

class Notifications extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: this.props.auth.notifications.comments,
      contracts: this.props.auth.notifications.contracts,
      newsletter: this.props.auth.notifications.newsletter,
      payments: this.props.auth.notifications.payments,
      productUpdates: this.props.auth.notifications.productUpdates,
      updated: false
    }
  
  }



  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  updateNotifications = () => {
    axios.post('/api/notifications', {
      comments: this.state.comments,
      contracts: this.state.contracts,
      newsletter: this.state.newsletter,
      payments: this.state.payments,
      productUpdates: this.state.productUpdates
    }).then(() => {
      this.setState({updated: true})
    })
  }

  render() {
    return (
      <div className="settings-box center-settings">
        <div>
          <h2>Send me emails when...</h2>
          <input
            name="comments"
            type="checkbox"
            checked={this.state.comments}
            onChange={this.handleInputChange}
          />
          <label>A client comments on a project</label>

          <input
            name="productUpdates"
            type="checkbox"
            checked={this.state.productUpdates}
            onChange={this.handleInputChange}
          />
          <label>ClientBird launches a major feature</label>

          <input
            name="payments"
            type="checkbox"
            checked={this.state.payments}
            onChange={this.handleInputChange}
          />
          <label>I recieve a payment</label>


          <input
            name="contracts"
            type="checkbox"
            checked={this.state.contracts}
            onChange={this.handleInputChange}
          />
          <label>A client signs a contract</label>

          <div className="small">
            <input
              name="newsletter"
              type="checkbox"
              checked={this.state.newsletter}
              onChange={this.handleInputChange}
            />
            <label>Subscribe me to the ClientBird newsletter</label>
          </div>


          <button onClick={this.updateNotifications}>Update</button>
        </div>

        {this.state.updated && (
          <div className="updated" onClick={() => this.setState({updated: false})}>
            Updated.
          </div>
        )}
      </div>
    )
  }
}

export default Notifications;