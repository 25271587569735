import React, { Component } from 'react';
import axios from 'axios';
import { fetchUser } from '../../../redux/actions';
import { connect } from 'react-redux';

import TopBar from '../TopBar';
import ContractClients from './ContractClients';


import './styles.scss';

class Clients extends Component {

  state = {
    clients: []
  }

  componentDidMount() {
    this.fetchClients();
  }

  fetchClients = () => {
    axios.get('/api/clients').then((clients) => {
      if (clients) {
        this.setState({clients: clients.data})
      } else {
        this.setState({clients: []})
      }
    })
  }

  render() {
    return (
      <div className="clients">
        <TopBar page="Clients" />
        <h1>Clients</h1>
        { this.state.clients &&
          this.props.auth.type === 'contractor' ? (
             <ContractClients user={this.props.auth} clients={this.state.clients} reRender={() => this.fetchClients()} /> 
        ) : (
          'You are a client, not a contractor.'
        )}
      </div>
    )
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, {fetchUser})(Clients);