import React from 'react';
import { Link }from 'react-router-dom';
import '../styles/components/topbar.scss';
// import NewProjectModal from './projects/NewProjectModal';

const TopBar = (props) => {
  const {
    back,
    page,
    showSettings,
    projectSettingsLink
  } = props;
  // const [showNew, setShowNew] = useState(false);

  // const renderBtn = () => {
  //   if (user.paidUser || !user.trialUsed) {
  //     switch(props.page) {
  //       case 'Dashboard':
  //         return <button className="new-project-cta" onClick={() => setShowNew(true)}>New Project</button>
  //       case 'Projects':
  //         return <button className="new-project-cta" onClick={() => setShowNew(true)}>New Project</button>
  //       default:
  //         return
  //     }  
  //   } else {
  //     return (
  //       <Link to="/upgrade" onClick={() => setShowNew(true)}>
  //         <button className="new-project-cta">Upgrade Now</button>
  //       </Link>
  //     )
  //   } 
  // }

  return (
    <div className="top-bar">
      <div className='left'>
      {back &&
        <Link to={back} className="dashboard-back-btn">Back</Link>
      }
      <strong className={back ? '' : 'move-top-bar-header'}>{page}</strong>
      {/* {user && renderBtn()} */}
      {/* {showNew &&
        <NewProjectModal leave={() => setShowNew(false)} />
      } */}
      </div>
      { (showSettings && projectSettingsLink) &&
          <Link to={props.projectSettingsLink}>
            <i className="fas fa-cog icon"></i>
          </Link>
      }

    </div>
  )
}

export default TopBar;