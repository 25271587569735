import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  addNewMilestone,
  getProject,
  deleteMilestoneComment
} from '../../../../../redux/actions';
import Project from './Project';
import CircularProgress from '@material-ui/core/CircularProgress';
import { findProjectCompletion } from  '../../../../../utils/projectCompletion';
import { withRouter } from 'react-router-dom';

const ProjectShow = (props) => {
  const {
    match,
    projects,
    auth,
    getProject,
    addNewMilestone,
    deleteMilestoneComment
  } = props;

  if (projects.error) {
    throw new Error(projects.error);
  }

  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState('')
  const [description, setDescription] = useState('')
  const [deadline, setDeadline] = useState('');
  const [milestoneId, setMilestoneId] = useState('');
  const [commentId, setCommentId] = useState('');

  useEffect(() => {
    if (!projects.data || projects.data._id !== match.params.id) {
      _getProject();
      //TODO: Should clear project data  in some places also.
    }
  // eslint-disable-next-line
  }, []);

  const _getProject = () => {
    getProject(match.params.id);
  }

  const _deleteMilestoneComment = () => {
    deleteMilestoneComment(match.params.id, milestoneId, commentId)
  }

  const _addNewMilestone = async () => {
    let milestones = projects.data.timeline;
    milestones.push({
      title,
      description,
      deadline
    });
    // Later we should refactor this back end call to allow
    // only one milestone being posted instead of whole list
    await addNewMilestone(projects.data._id, milestones);
    await getProject(projects.data._id);
    toggleModal();
  }

  const toggleModal = () => {
    setModal(!modal)
    setTitle('')
    setDescription('')
    setDeadline('')
  }

  const _findProjectCompletion = (project) => {
    return findProjectCompletion(project)
  }
  
  return (
    <>
      {projects.data === null ?
          <div className='loadingContainer'>
            <CircularProgress />
          </div>
        :
          <Project
            project={projects.data}
            before={projects.before}
            future={projects.future}
            _addNewMilestone={_addNewMilestone}
            auth={auth}
            toggleModal={toggleModal}
            deadline={deadline}
            description={description}
            title={title}
            modal={modal}
            setTitle={setTitle}
            setDescription={setDescription}
            setDeadline={setDeadline}
            _findProjectCompletion={_findProjectCompletion}
            _getProject={_getProject}
            deleteMilestoneComment={() => _deleteMilestoneComment()}
            milestoneId={milestoneId}
            setMilestoneId={setMilestoneId}
            commentId={commentId}
            setCommentId={setCommentId}
          />
      }
    </>
  )
}

function mapStateToProps({ projects, auth }) {
  return {
    projects,
    auth
  };
}

function mapDispatchToProps() {
  return {
    addNewMilestone,
    getProject,
    deleteMilestoneComment
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(withRouter(ProjectShow));
