import React, { Component } from "react";

import { connect } from "react-redux";
import { fetchUser } from '../../redux/actions';


import TopBar from '../dashboard/TopBar';

import CheckoutForm from "./CheckoutForm";



import "./styles.scss";

class Upgrade extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }

  render() {
    return (
      <div>
        <TopBar page="Upgrade" />
        <div className="upgrade-page">
        {this.props.auth.paidUser ? (
          <div>
            <h3>Your account has been upgraded!</h3>
          </div>
        ) : (
          <CheckoutForm  auth={this.props.auth} fetchUser={this.props.fetchUser}/>
        )}
        </div>
      </div>
    );
  }
}


function mapStateToProps({ auth }) {
  return { auth };
}

export default connect(mapStateToProps, { fetchUser })(Upgrade);
