import React from 'react';
import ClickOutHandler from 'react-onclickout';
import './styles.scss';

const NewMilestoneModal = (props) => {
  const {
    addNewMilestone,
    setDescription,
    setTitle,
    description,
    title,
    deadline,
    setDeadline,
    toggleModal
  } = props;
  
  return (
    <div className="modal-overlay">
      <ClickOutHandler onClickOut={toggleModal}>
        <div className="modal project-modal">
          <h2 className='title'>New Milestone</h2>
          <label>Title</label>
          <input value={title} onChange={(e) => setTitle(e.target.value)} />
          <label>Description</label>
          <input value={description} onChange={(e) => setDescription(e.target.value)} />
          <label>Deadline</label>
          <input type="date" value={deadline} onChange={(e) => setDeadline(e.target.value)} />
          <button
            className='btn-primary'
            onClick={addNewMilestone}
          >
            Create
          </button>
        </div>
      </ClickOutHandler>
    </div>
  )
}

export default NewMilestoneModal;
