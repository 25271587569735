import React, { Component } from 'react';
import axios from 'axios';

class Information extends Component {
  constructor(props) {
    super(props)

    this.state = {
      firstName: this.props.auth.firstName,
      lastName: this.props.auth.lastName,
      email: this.props.auth.email,
      businessName: this.props.auth.businessName,
      businessWebsite: this.props.auth.businessWebsite,
      city: this.props.auth.city,
      phoneNumber: this.props.auth.phoneNumber,
      updated: false
    }
  
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  validatePhone = (e) => {
    let phone = e.target.value.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    phone = !phone[2] ? phone[1] : '(' + phone[1] + ') ' + phone[2] + (phone[3] ? '-' + phone[3] : '');
    this.setState({phoneNumber: phone});
  }


// /api/user/update
  updateUser = () => {
    axios.post('/api/user/update', {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
      businessName: this.state.businessName,
      businessWebsite: this.state.businessWebsite,
      city: this.state.city,
      phoneNumber: this.state.phoneNumber
    }).then(() => {
      this.setState({updated: true})
    })
  }

  render() {
    return (
      <div className="settings-box half-and-half">
        <div>
          <input name="firstName" placeholder="First Name" value={this.state.firstName} onChange={this.handleInputChange} />
          <input name="businessName" placeholder="Business" value={this.state.businessName} onChange={this.handleInputChange} />
          <input name="email" placeholder="Email" value={this.state.email} onChange={this.handleInputChange} />
          <input name="businessWebsite" placeholder="Website" value={this.state.businessWebsite} onChange={this.handleInputChange} />
          {this.state.updated && (
            <div className="updated" onClick={() => this.setState({updated: false})}>
              Updated.
            </div>
           )}
        </div>

        <div>
          <input name="lastName" placeholder="Last Name" value={this.state.lastName} onChange={this.handleInputChange} />
          <input name="city" placeholder="City" value={this.state.city} onChange={this.handleInputChange} />
          <input name="phoneNumber" placeholder="Phone" value={this.state.phoneNumber} onChange={(e) => this.validatePhone(e)} />
          <button onClick={this.updateUser}>Update</button>
        </div>



      </div>
    )
  }
}

export default Information;