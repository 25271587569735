import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment'

export default function ProjectDeadlines({projects}) {
  return (
    <>
      <div className="deadline-project-box">
        <div className="deadline-header">
          <h2>Projects</h2>
        </div>
        {projects ? projects.map((item) => {
            return (
            <Link to={'/projects/' + item._id} key={item._id} className="client-row hover-effect">
              <div>{item.title}{item.projectName}</div>
              <div className="capitalize">{moment(item.deadline).fromNow()}</div>
            </Link>)
            }) : <p>Loading...</p>
          }
                {projects.length < 1 ? <p className="client-row">No upcoming deadlines</p> : ''}

      </div>
    </>
  )
}