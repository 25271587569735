import React, { Component } from 'react';
import axios from 'axios';

class Step1 extends Component {
    state = {
      contractorTypes: ['Software', 'Design', 'Consulting', 'Marketing', 'Other']
    }

    submitType = (contractorType) => {
      axios.post('/api/setup/contractor/1', {contractorType}).then((res) => {
        this.props.history.push('/setup/final');
      });
    }

    render() {
        return (
          <div className="setup">
            <h1>ClientBird</h1>
            <h2>What type of work do you do?</h2>
            <ul className='list-types'>
            { this.state.contractorTypes.map((contractorType) => {
              return <li key={contractorType} onClick={() => this.submitType(contractorType)}>{contractorType}</li>
            })
            }
            </ul>

            <p onClick={() => this.props.history.goBack()} className="back-btn">Back</p>
          </div>
        );
    }
}

export default Step1;