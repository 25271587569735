import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchUser } from '../../redux/actions';
import TopBar from '../dashboard/TopBar';

import Information from './Information';
import Notifications from './Notifications';

import './styles.scss';


class Settings extends Component {
  componentDidMount() {
    this.props.fetchUser();
  }
  render() {
    return (
      <div>
        <TopBar page="Personal Settings" />

        <div className="settings-containers">
          <h1>Personal Settings</h1>
          <Information auth={this.props.auth} />

          <h1>Notifications</h1>
          <Notifications auth={this.props.auth} />

        </div>
      </div>
    )
  }
}

function mapStateToProps({ auth })  {
  return {
    auth
  };
}

function mapDispatchToProps() {
  return {
    fetchUser
  };
};


export default connect(mapStateToProps, mapDispatchToProps())(Settings);