import React from 'react';
import { FETCH_STATUSES } from '../../data/base/types.ts';
import './styles.scss';
import { connect } from 'react-redux';

const ErrorBoundary = (props) => {    
    if (props.projects.status === FETCH_STATUSES.ERROR) {
        return (
            <div className='error-page'>
                <h1> Whoops!</h1>
                <p>Something went wrong</p>
                <button href='/'>Refresh</button>
                <br/>
                <p>Contact Support.</p>
            </div>
        )
    } else {
        return props.children
    }
};


function mapStateToProps({ projects, clients, auth }) {
    return {
      projects,
      clients,
      auth
    };
  }
  
export default connect(mapStateToProps)(ErrorBoundary)