import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchDashboardData, fetchUser } from '../../redux/actions';
import TopBar from './TopBar';

import Activity from './dash-modules/Activity';
import StatCard from '../StatCard';

import ProjectDeadlines from './dash-modules/ProjectDeadlines';
import MilestoneDeadlines from './dash-modules/MilestoneDeadlines';
import Completed from './dash-modules/Completed';

import InfoCard from '../InfoCard';
import './styles.scss';
import '../styles/common.scss';

class Dashboard extends Component {

  componentDidMount() {
    this.props.fetchDashboardData();
    this.props.fetchUser();
  }

  render() {
    return (
      <div className="dashboard">
        <TopBar page="Dashboard" />
          <div className='top-section'>
            <div>
            <h1 className='h1'>Dashboard</h1>
            {!this.props.auth.showTutorial && <h2>Recent <span className="blue-text">activity</span></h2>}
            {this.props.auth.showTutorial && this.props.auth.type === 'contractor' ? (
              <InfoCard name={this.props.auth.businessName} info="We’re excited to have you onboard and look forward to seeing your business fly with Client Bird."/>
            ) : ''}
            </div>
              {this.props.auth.type === 'contractor' &&
              <div className="stats-cards">
                <StatCard variant={true} number={this.props.auth.clients.length} title='Clients' emoji='🎉'/>
                <StatCard number={this.props.projects.projects.length} title='Projects' emoji='💻'/>
              </div>
              }
          </div>

          <h2 style={{marginBottom: !this.props.auth.showTutorial ? '-14rem' : '0.424rem'}}>
            Recent <span className="blue-text">activity</span></h2>
        <div className="deadlines-container">
          <Activity comments={this.props.dashboard.comments} />
        </div>

        <h2>Upcoming <span>deadlines</span></h2>

        <div className="deadlines-container">

          <ProjectDeadlines projects={this.props.dashboard.projects} />
          <MilestoneDeadlines milestones={this.props.dashboard.milestones} />
          <Completed completed={this.props.dashboard.completed} />

        </div>

        <p>Fly back soon, we're pushing updates <span>several times a day!</span></p>


      </div>
    )
  }
}

function mapStateToProps({ auth, dashboard, projects })  {
  return {
    auth,
    dashboard,
    projects
  };
}

function mapDispatchToProps() {
  return {
    fetchDashboardData,
    fetchUser
  };
};


export default connect(mapStateToProps, mapDispatchToProps())(Dashboard);