import React, { Component } from 'react';

import axios from 'axios';
import * as moment from 'moment'

class Conversation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      comments: this.props.comments,
      showWriter: false,
      commentContent: '',
      editComment: '',
      editValue: ''
    }
  }

  addComment = () => {
    axios.post(`/api/project/${this.props.id}/comment`, {content: this.state.commentContent}).then((res) => {
      let comments = res.data;
      this.setState({comments, showWriter: false, commentContent: ''})  
    })
  }

  editComment = (comment) => {
    const newContent = this.state.editValue;
    axios.post(`/api/project/${this.props.id}/comment/${comment._id}/edit`, {newContent}).then((res) => {
      if (res.data === 'comment not found') {
        // do nothing lol
        return
      } else {
        this.setState({comments: res.data, editComment: '', editValue: ''})
      }
    })
  }

  deleteComment = (commentId) => {
    axios.post(`/api/project/${this.props.id}/comment/${commentId}/delete`).then((res) => {
      if (res.data === 'comment not found') {
        // do nothing lol
        return
      } else {
        this.setState({comments: res.data})
      }
    })
  }

  likeComment = (commentId) => {
    axios.post(`/api/project/${this.props.id}/comment/${commentId}/like`).then((res) => {
      this.setState({comments: res.data.comments})
    })
  }

  momentify(time) {
    const dateObj = new Date(time)
    return moment(dateObj).fromNow()
  }

  renderLikedText = (likes) => {
    if (!likes.length) {
      return ''
    }

    if (likes.length === 1) {
      return (
        `${likes[0].firstName} likes this`
      )
    }

    if (likes.length === 2) {
      return (
        `${likes[0].firstName} and ${likes[1].firstName} like this`
      )
    }

    if (likes.length === 3) {
      return (
        `${likes[0].firstName}, ${likes[1].firstName}, and ${likes[2].firstName} like this`
      )
    }

    if (likes.length >= 4) {
      return (
        `${likes.length} people like this`
      )
    }

  }

  render() {
    return (
      <div className="conversation-container">
        {this.state.comments.map((comment, index) => {
          return (
            <div key={comment.date} className="comment">
            <img className="comment-photo" alt="profile" src={comment.profilePhoto} />
            <div>
              <div className="comment-info">
                <p>
                  <strong>{comment.user}</strong> <span className="date">- {this.momentify(comment.date)}</span>

                  {comment.edited ? <span className="date">( Edited )</span> : ''}

                  {this.props.user.firstName + ' ' + this.props.user.lastName === comment.user ? (
                    <button onClick={() => this.setState({editComment: comment._id, editValue: comment.content})} className="edit-btn">
                      Edit
                    </button>
                  ) : ''}
                </p>
              </div>
              <div className="comment-content">

                {this.state.editComment === comment._id ? (
                  <div className="write-comment edit-mode">
                    <textarea onChange={(e) => this.setState({editValue: e.target.value})} value={this.state.editValue} placeholder="Compose your comment" />
                    <button
                      onClick={() => this.editComment(comment)}
                      className='btn-primary'
                    >
                      Save
                    </button>
                    <button className="cancel-btn" onClick={() => this.setState({editComment: '', editValue: ''})}>Cancel</button>
                  </div>
                ) : (
                  <p 
                  style={{wordBreak: 'break-word'}}
                  
              >{comment.content}</p>
                )}

                
              </div>

              <div className="like-section">
                <button onClick={() => this.likeComment(comment._id)}><i className="far fa-thumbs-up"></i>
                  {comment.likes.some(like => like.userId === this.props.user._id) ? ' Unlike' : ' Like'}
                </button>
                <div className="comment-likes">
                  {this.renderLikedText(comment.likes)}
                </div>
              </div>

            </div>

            {this.props.user.firstName + ' ' + this.props.user.lastName === comment.user ? (
              <button onClick={() => this.deleteComment(comment._id)} className="delete-btn">
                <i className="fas fa-trash-alt"></i>
              </button>
            ) : ''}
          
          </div>  
          )
        })}
        <br />

        <button
          className="btn-primary"
          onClick={() => {
            this.setState({
              showWriter: !this.state.showWriter
            })
          }}
        >
          {!this.state.showWriter ? "Add Comment" : "Hide"}
        </button>

        {this.state.showWriter ? (
          <div className="write-comment">
            <textarea value={this.state.commentContent} onChange={(e) => this.setState({commentContent: e.target.value})} placeholder="Compose your comment" />
            <button className="btn-primary" onClick={this.addComment}>Send</button>
            <button
            className="cancel-btn"
            onClick={() => this.setState({
              showWriter: !this.state.showWriter
              })
            }>
              Cancel
            </button>
          </div>
        ) : ''}

      </div>
    )
  }
}

export default Conversation;