import React from 'react';
import { Link } from 'react-router-dom';

export default function Completed({completed}) {
  return (
    <>
      <div className="deadline-completed-box">
        <div className="deadline-header">
          <h2>Completed</h2>
        </div>
        {completed ? completed.map((item) => {
          return (
          <Link to={'/projects/' + item.url} key={item._id} className="client-row hover-effect">
            <div>{item.title}{item.projectName}</div>
          </Link>)
        })
        : <p>Loading...</p>
      }
            {completed.length < 1 ? <p className="client-row">No completed projects yet.</p> : ''}

      </div>
    </>
  )
}