import React, { useState, useEffect } from 'react';
import '../../../../styles/pages/settings.scss';
import {
  CircularProgress
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { FETCH_STATUSES } from '../../../../../data/base/types';
import ClickOutHandler from 'react-onclickout';
import { Link } from 'react-router-dom';
import { updateProject, addNewClient } from '../../../../../data/api/project/project';
import DeleteModal from '../../../../../components/DeleteModal';
import TopBar from '../../../TopBar';
import axios from 'axios';

const ProjectSettings = (props) => {
    const {
      project,
      status,
      auth,
      clients,
      getProject,
      deleteProject,
      match
    } = props;

    const [updatedProject, setUpdatedProject] = useState(project);
    const [showClients, setShowClients] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [showError, setShowError] = useState('');
    const [contractor, setContractor] = useState([]);
    const [updateStatus, setUpdateState] = useState(FETCH_STATUSES.OK);

    useEffect(() => {
      if (!project || project._id !== match.params.id) {
        getProject(match.params.id);
      }
      if (auth.type === 'client') {
        axios.get(`/api/contractor/${props.auth.contractorId}`).then((res) => {
          setContractor(res.data);
        })
      }
    // eslint-disable-next-line
    }, []);
    
    const _updateProject = async () => {
      setUpdateState(FETCH_STATUSES.LOADING)
      try {
        await updateProject(project._id, updatedProject);
        setUpdateState(FETCH_STATUSES.OK);
        getProject(match.params.id);
      } catch (e) {
        setUpdateState(FETCH_STATUSES.ERROR);
      }
    }

    const _addNewClient = async (client) => {
      try {
        const res = await addNewClient(project._id, client);
        if (res === 'You already have that client') {
          showError(res.data);
        } else {
          getProject(project._id);
          setShowClients(false);
          setShowError('');  
        }
      } catch (e) {
        //TODO: handle this error in ux.
        console.log('error:', e);
      }
    }

  if (!project || !updatedProject || updateStatus === FETCH_STATUSES.LOADING) {
    return (
      <div className='loadingContainer'>
        <CircularProgress />
      </div>
    )
  }


  return (
    <>
      <TopBar
        page={`Projects - ${project.projectName}`}
        back={`/projects/${project._id}`}
      />
      <div className='settings-page'>
        <p className='title'>Project Settings</p>
        <div className='section'>
        <p className='title2'>Basic Info</p>
          <input
            placeholder='Project Title'
            label="Name"
            value={updatedProject.projectName}
            onChange={(e) => setUpdatedProject({...project, projectName: e.target.value})}
          />
          <input
            placeholder='Project Link'
            label="Link"
            value={updatedProject.link}
            onChange={(e) => setUpdatedProject({...project, link: e.target.value})}
          />
          <input
            placeholder='Project Description'
            label="Description"
            value={updatedProject.description}
            onChange={(e) => setUpdatedProject({...project, description: e.target.value})}
          />
          <button
            onClick={_updateProject}
            className='btn-primary'
            disabled={status === FETCH_STATUSES.LOADING}
          >
            Update
          </button>
        </div>

        {/* show or hide the add client button depending on user type */}
        <h2 className="client-title">{auth.type === 'client' ? (
          <>
            Contractor
          </> 
          ) : (
            <div className="add-clients-con">
              Clients
              <i
                class="add-clients fas fa-plus-circle"
                onClick={
                  ()  => setShowClients(!showClients)
                }
              />
              {showClients ? (
                <ClickOutHandler onClickOut={() => setShowClients(!showClients)}>
                  <div className="employee-cal-drop-down on-edit add-client">
                    <p><Link to="/clients">Invite new client</Link></p>
                    {clients.map((data) => (
                      <p
                        key={data._id}
                        onClick={
                          () => _addNewClient(data)
                        }
                      >
                        {data.firstName} {data.lastName}
                      </p>
                    ))}
                  </div>
                </ClickOutHandler>
                ) : '' 
              }
            </div>
          )}
        </h2>
        <div className="client-data">
          <div className="client-header">
            <b>Name</b>
            <b>Email</b>
            <b>Phone</b>
          </div>
              {auth.type === 'contractor' ? project.clients.map((client) => (
                <div className="client-row">
                  <div>{client ? client.firstName : ''} {client ? client.lastName : ''}</div>
                  <div>{client ? client.email : ''}</div>
                  <div>{client ? client.phoneNumber : ''}</div>
                </div>
              )) : 
                <div className="client-row">
                  <div>{contractor ? contractor.firstName : ''} {contractor ? contractor.lastName : ''}</div>
                  <div>{contractor ? contractor.email : ''}</div>
                  <div>{contractor ? contractor.phone : ''}</div>
                </div>
              }

        </div>
        {showError === 'You already have that client' ? <div onClick={() => setShowError({showError: ''})}className="red-text pointer padding collection-item">{showError}</div> : null}

        <h3>Danger Zone</h3>
        { deleteModalOpen &&
          <DeleteModal
            deleteProject={deleteProject}
            setDeleteModalOpen={setDeleteModalOpen}
          />
        }
        <button
          className="btn-danger"
          onClick={
            () => setDeleteModalOpen(true)
          }
        >
          Delete Project
        </button>
      </div>
    </>
  );
};

export default withRouter(ProjectSettings);
