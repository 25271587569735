// action/index.js

import {
  FETCH_USER,
  FETCH_CLIENTS,
  FETCH_PROJECTS,
  FETCH_DASHBOARD_DATA,
  CREATE_PROJECT,
  GET_PROJECT_LOADING,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_ERROR, 
  DELETE_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_ERROR,
  UPDATE_PROJECT_LOADING, 
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  CLEAR_PROJECT_ERROR, 
  CREATE_NEW_MILESTONE,
  DELETE_MILESTONE_COMMENT_LOADING,
  DELETE_MILESTONE_COMMENT_SUCCESS,
  DELETE_MILESTONE_COMMENT_ERROR
} from './types';

import axios from 'axios';

// Authentication
export const fetchUser = () => async dispatch => {
  const res = await axios.get('/api/current_user');
  dispatch({
    type: FETCH_USER,
    payload: res.data
  });
};

// Clients
export const fetchClients = () => async dispatch => {
  const res = await axios.get('/api/clients');

  dispatch({ type: FETCH_CLIENTS, payload: res.data });
}

// Projects
export const fetchProjects = () => async dispatch => {
  const res = await axios.get('/api/projects');
  dispatch({ type: FETCH_PROJECTS, payload: res.data });
}

export const createProject = (projectData) => async dispatch => {
  const res = await axios.post('/api/project/create', {projectName: projectData.newTitle, client: projectData.client, deadline: projectData.deadline, description: projectData.description, link: projectData.link});
  dispatch({ type: CREATE_PROJECT, payload: res.data});
}

export const addNewMilestone = (projectId, milestones) => async dispatch => {
  const res = await axios.post(`/api/project/${projectId}/milestone/new`, { milestones });
  dispatch({
    type: CREATE_NEW_MILESTONE,
    payload: res.data.timeline
  });
}

export const deleteProject = (id) => async dispatch => {
  dispatch({type: DELETE_PROJECT_LOADING})
  try {
    axios.delete(`/api/project/${id}`);
    dispatch({ type: DELETE_PROJECT_SUCCESS });
  } catch (e) {
    dispatch({ type: DELETE_PROJECT_ERROR });
  }
}

export const updateProject = (id, updatedProject) => async dispatch => {
  dispatch({type: UPDATE_PROJECT_LOADING})
  try {
    await axios.put(`/api/project/${id}`, updatedProject);
    dispatch({
      type: UPDATE_PROJECT_SUCCESS
    });
  } catch (e) {
    dispatch({
      type: UPDATE_PROJECT_ERROR
    });
  }
}

export const deleteMilestoneComment = (projectId, milestoneId, commentId) => async dispatch => {
  dispatch({type: DELETE_MILESTONE_COMMENT_LOADING})
  try {
    const res = await axios.delete(
      `/api/project/${projectId}/milestone/${milestoneId}/comment/${commentId}`
    );
    dispatch({
      type: DELETE_MILESTONE_COMMENT_SUCCESS,
      payload: res.data
    });
  } catch (e) {
    dispatch({
      type: DELETE_MILESTONE_COMMENT_ERROR
    });
  }
}

export const getProject = (id) => async dispatch => {
  dispatch({
    type: GET_PROJECT_LOADING
  });
  try {
    const res = await axios.get(`/api/project/${id}`);    
    dispatch({
      type: GET_PROJECT_SUCCESS,
      payload: {
        project: res.data.project,
        before: res.data.before,
        future: res.data.future
      }
    });
  } catch (e) {
    dispatch({
      type: GET_PROJECT_ERROR
    });
  }
};

// Probably not the best way to handle this but doing so for now...
export const clearProjectErrors = () => async dispatch => {
  dispatch({
    type: CLEAR_PROJECT_ERROR,
  })
}

// Contractor Dashboard
export const fetchDashboardData = () => async dispatch => {
    try {
      let comments = await axios.get('/api/activity');
      let projects = await axios.get('/api/deadlines/projects');
      let milestones = await axios.get('/api/deadlines/milestones');
      let completed = await axios.get('/api/deadlines/completed');
      dispatch({
        type: FETCH_DASHBOARD_DATA,
        payload: {
          comments: comments.data,
          projects: projects.data,
          milestones: milestones.data,
          completed: completed.data
        }
      })
    } catch(err) {
      console.error('err:', err);
    }
}
