import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment';

class ProjectCard extends Component {

  state = {
    hoverIndex: ''
  }

  render() {
    return (
      <Link to={'/projects/' + this.props.project._id} key={this.props.project._id} className="project-container smaller">
      <div className='project-content'>
        <h3 className='project-title'>{this.props.project.title}{this.props.project.projectName}</h3>
        <div className='link-container'>
        <i className="fas fa-external-link-alt"></i>
        <p className='project-sitelink'>{this.props.project.link}</p>
        </div>
        <p className='project-bio'>{this.props.project.description}</p>



        <div className="percent-value-container">
          {
            this.props.percent !== 0 &&
            <p className="percent-value" style={{width: (this.props.percent) + '%', textAlign: 'right'}}>{this.props.percent}%</p>
          }
        </div>
        <div className='progress-bar-sm'>
          <div className='filled-bar-sm' style={{width: this.props.percent + '%'}}></div>
        </div>

        <p className="comment-length-number">
          {this.props.project.comments && this.props.project.comments.length >= 1 ? (
            `${this.props.project.comments.length > 1 ? this.props.project.comments.length + ' project comments' : this.props.project.comments.length + ' project comment'}`
            ) : ''}
        </p>


        <div className='bottom-slot'>
        {
          this.props.project.deadline !== 'Invalid date' &&
            <div className='days-left'>
              <i className="far fa-clock"></i>
              <p className='days-amnt'>{moment(this.props.project.deadline).fromNow()}</p>
            </div>
         }
          


         <div className="relative">
            {this.props.project.clients.map((client, index) => {
              if (index > 2) {
                return null;
              }
              return (
                <>
                  {this.state.hoverIndex === `${index} ${this.props.projectIndex}` ? <div className="client-tooltip">{client.firstName} {client.lastName}</div> : ''}
                  <img
                    onMouseEnter={() => this.setState({hoverIndex: `${index} ${this.props.projectIndex}`})}
                    onMouseLeave={() => this.setState({hoverIndex: ''})}
                    alt="client"
                    className='client-img'
                    src={client.profilePhoto}
                  ></img>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </Link>

    )
  }
}

export default ProjectCard;