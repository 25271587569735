// dashboardReducer.js

import { FETCH_DASHBOARD_DATA } from '../actions/types';

const defaultState = {
  projects: [],
  comments: [],
  milestones: [],
  completed: []
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case FETCH_DASHBOARD_DATA:    
      return {...state,
        projects: action.payload.projects,
        comments: action.payload.comments,
        milestones: action.payload.milestones,
        completed: action.payload.completed,
      }
    default:
      return state;
  }
}
