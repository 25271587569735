import React, { Component } from 'react';
import StripeCheckout from 'react-stripe-checkout';
import axios from 'axios';



class CheckoutForm extends Component {

  state = {
    plan: this.props.auth ? this.props.auth.plan : 'Free',
    success: false,
    loading: false,
    key: ''
  }

  componentDidMount() {
    axios.get('/api/stripe/key').then((res) => {
      this.setState({key: res.data})
    })
  }


  onToken = (token) => {

    this.toggleLoader();

    axios.post('/api/billing', token).then((response) => {
      this.props.fetchUser();
      this.setState({success: true, plan: this.props.auth.plan, loading: !this.state.loading});
    })
  }

  toggleLoader() {
    this.setState({loading: !this.state.loading});
  }

  render() {
    return (
      <div className="upgrade-card">
        <div>
        <h2>Upgrade to Enterprise</h2>
        <p>Empower your business with additional features for 14.95 per month.</p>
          <div>
            {this.state.key ? (
                <StripeCheckout
                  name="ClientBird"
                  description="Upgrade Your Account"
                  image="https://media.licdn.com/dms/image/C560BAQE_dWzmhp7fow/company-logo_400_400/0?e=1573689600&v=beta&t=2gfv9cuTFhMeN9VkFr2Ye7DRRHydMi0DST2Wpu3wgcA"
                  ComponentClass="checkout-btn"
                  panelLabel="Upgrade Account"
                  amount={1495}
                  currency="USD"
                  token={this.onToken}
                  stripeKey={this.state.key}
              />
            ) : 'loading'}
          </div>

          {this.state.loading ? (
          <div className="done-box">
            <h3>Loading</h3>
          </div>
           ) : ''}

          {this.state.success ? (
            <div onClick={() => this.setState({success : false})} className="done-box">
              <h3>Your account has been upgraded!</h3>
            </div>
          ) : ''}

        </div>
        <img width="400px" alt="checkout cartoon" src="https://i.ibb.co/wQcbtm9/undraw-modern-professional-yt9h.png" />

       
      </div>
    )
  }
}

export default CheckoutForm;