import axios from 'axios';
// import { BaseLivlyApiResponse } from 'data/base/types';
// import { LeaseUtilitiesData } from './utilities.types';

export const updateProject = async (
  projectId: string,
  updatedProject: object
): Promise<any> => {
  let res = await axios.put(
    `/api/project/${projectId}`,
    updatedProject
  );
  return res.data;
};

export const addNewClient = async (
  projectId: string,
  client: any
  ): Promise<any> => {
    let res = await axios.post(
      `/api/project/${projectId}/client`,
      {client}
    );
    return res.data;
};

export const deleteComment = async (
  projectId: string,
  milestoneId: string,
  commentId: string
  ): Promise<any> => {
  let res = await axios.delete(`/api/project/${projectId}/milestone/${milestoneId}/comment/${commentId}`);
  return res.data; // returns comments on that milestons
}
