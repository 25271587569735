import {
  CREATE_NEW_MILESTONE,
  FETCH_PROJECTS,
  CREATE_PROJECT,
  GET_PROJECT_LOADING,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_ERROR,
  DELETE_PROJECT_ERROR,
  DELETE_PROJECT_LOADING,
  DELETE_PROJECT_SUCCESS,
  UPDATE_PROJECT_LOADING, 
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_ERROR,
  CLEAR_PROJECT_ERROR,
  DELETE_MILESTONE_COMMENT_LOADING,
  DELETE_MILESTONE_COMMENT_SUCCESS,
  DELETE_MILESTONE_COMMENT_ERROR
} from '../actions/types';

import { FETCH_STATUSES } from '../../data/base/types';

const defaultState = {
  status: FETCH_STATUSES.OK,
  data: null,
  before: [],
  future: [],
  projects: []
}

export default function(state = defaultState, action) {
  switch (action.type) {
    case FETCH_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };
    case GET_PROJECT_LOADING:
      return {
        ...state,
        status: FETCH_STATUSES.LOADING,
        data: null
      };
    case GET_PROJECT_SUCCESS:      
      return {
        ...state,
        data: action.payload.project,
        before: action.payload.before,
        future: action.payload.future,
        status: FETCH_STATUSES.OK
      };
    case GET_PROJECT_ERROR:
      return {
        ...state,
        status: FETCH_STATUSES.ERROR,
        error: 'Internal Service Error',
        data: null
      };
    case CLEAR_PROJECT_ERROR:
      return {
        ...state,
        status: FETCH_STATUSES.OK,
        error: null,
      }
    case DELETE_PROJECT_LOADING:
      return {
        ...state, 
        error: null, 
        status: FETCH_STATUSES.LOADING
      }
    case DELETE_PROJECT_ERROR:
      return {
        ...state, 
        error: 'Internal Service Error', 
        status: FETCH_STATUSES.ERROR
      }
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state, 
        data: null, 
        status: FETCH_STATUSES.OK
      }
    case UPDATE_PROJECT_LOADING:
        return {
          ...state, 
          error: null, 
          status: FETCH_STATUSES.LOADING
        }
    case UPDATE_PROJECT_ERROR:
      return {
        ...state, 
        error: 'Uh Oh! Try again or contact support.', 
        status: FETCH_STATUSES.ERROR
      }
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        status: FETCH_STATUSES.OK
      }
    case CREATE_PROJECT:
      const projectData = action.payload;
      state.projects.push(projectData)
      return {
        ...state,
        projects: state.projects,
        data: projectData
      }
    case CREATE_NEW_MILESTONE:
      return {
        ...state,
        data: {
          ...state.data,
          timeline: action.payload
        }
      }
    case DELETE_MILESTONE_COMMENT_LOADING:
      return {
        ...state, 
        error: null, 
        status: FETCH_STATUSES.LOADING,
        data: {
          ...state.data,
          timeline: null
        }
      }
    case DELETE_MILESTONE_COMMENT_SUCCESS:
      return {
        ...state,
        status: FETCH_STATUSES.OK,
        data: {
          ...state.data,
          timeline: action.payload
        }
      }
    case DELETE_MILESTONE_COMMENT_ERROR:
      return {
        ...state, 
        error: 'Uh Oh! Try again or contact support.', 
        status: FETCH_STATUSES.ERROR
      }
    default:
      return state;
  }
}
