import React, { Component } from 'react';
import axios from 'axios';
import meeting from '../../../assets/setup/meeting.svg';

class Step1 extends Component {
    state = {
      contractorId: ''
    }

    componentDidMount() {
      this.setState({contractorId: localStorage.getItem('contractor')});
    }

    submitInfo = (contractorId) => {
      axios.post('/api/setup/client/1', {contractorId}).then((res) => {
        this.props.history.push('/setup/final');
      }).catch((err) => {
        console.error(err);
        
      });
    }

    render() {
        return (
          <div className="setup">
            <h1>ClientBird</h1>
              <img src={meeting} className='card-img' alt='decide'></img>
              <h2 className="up">Enter your Contractors Identifier</h2>
              <p>(Ask them if you don't have it)</p>
              <div className="form">
                <input className='contractorId-input' type="text" value={this.state.contractorId} onChange={(e) => this.setState({contractorId: e.target.value})} placeholder="2345r2q345" />
              </div>
    
              <button onClick={() => this.submitInfo(this.state.contractorId)} className="last-step-btn">Continue</button>
              <p onClick={() => this.props.history.goBack()} className="back-btn">Back</p>
          </div>
        );
    }
}

export default Step1;