import React from 'react';
import './styles/common.scss';
import { Link } from 'react-router-dom';
import './styles/components/infocard.scss';

const InfoCard = (props) => {
    return (
        <div className="info-card">
            <h1 className='welcome-title'>WELCOME, {props.name}</h1>
            <p>{props.info}</p>
            <Link to='projects/'>
                <button className='first-time-btn'>Start your first project</button>
            </Link>
        </div>
    )
} 

export default InfoCard;
