import React from 'react';
import { Link } from 'react-router-dom';
import * as moment from 'moment'


function momentify(time) {
  const dateObj = new Date(time)
  return moment(dateObj).fromNow()
}

export default function Activity({comments}) {
  return (
    <>
      <div className="sixty">
        <div className="client-header shorter">
            <h2>Comments</h2>
        </div>
        <div className="activity-project-box">
          {comments ?
            comments.map((comment) => {
            return (
            <>
            {/*<Link to={'/projects/' + comment.projectId + (comment.milestoneName ? `/${comment.milestoneId}` : '') } key={comment._id}>*/}
            <Link to={'/projects/' + comment.projectId } key={comment._id}>
              <div key={comment.date} className="comment">
                <img className="comment-photo" alt="profile" src={comment.profilePhoto} />
                <div>
                  <div className="comment-info">
                    <p>
                      <strong>
                        {comment.projectName} - {momentify(comment.date)}
                      </strong>
                    </p>
                    <p><strong>{comment.user}</strong> </p>
                  </div>
                  <div className="comment-content">
                  <p>{comment.content}</p>
                  <span className="small-grey">{comment.milestoneName ? `On Milestone: ${comment.milestoneName} ` : ''}</span>
                  </div>
                </div>
            </div>  
            <div className="line"></div>
            </Link>
            </>
            )
              })
          : <p>Loading...</p>
        }

        {comments.length < 1 ? <p className="client-row">No recent activity</p> : ''}
        </div>


      </div>
    </>
  )
}