import React, { Component } from 'react';
import ClickOutHandler from 'react-onclickout';

class AddLinkForm extends Component {

  state = {
    showColors: false,
    colors: ['blue', 'red'],
    color: '',
    title: '',
    link: ''
  }

  addLink = () => {
    let link = {
      title: this.state.title,
      url: this.state.link,
      background: this.state.color
    }
    this.props.addLink(link);
  }

  render() {
    return (
      <div className="add-link-form">
        <label>Title</label>
        <input onChange={(e) => this.setState({title: e.target.value})} placeholder="Title" />
        <label>Link</label>
        <input onChange={(e) => this.setState({link: e.target.value})} placeholder="Link" />
        <label>Color</label>
        <div onClick={() => this.setState({showColors: !this.state.showColors})} className="card-input-imp select-employee-form">{this.state.color ? this.state.color : 'Select Color'}</div>
          {this.state.showColors ? (
            <ClickOutHandler onClickOut={() => this.setState({showColors: !this.state.showColors})}>
              <div className="employee-cal-drop-down on-edit">
                {this.state.colors.map((color, index) => (
                  <p key={color} onClick={() => this.setState({color: color, showColors: !this.state.showColors})}>{color}</p>
                ))}
              </div>
            </ClickOutHandler>
          ) : '' 
          }

        <div className="space-between">
          <button onClick={this.addLink}>Add</button>
          <button onClick={this.props.close}>Cancel</button>
        </div>

        <div className="line"></div>
      </div>
    )
  }
}

export default AddLinkForm